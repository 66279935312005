@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700&display=swap");

body {
  overflow-x: hidden;
  min-width: 320px;
  background: #fff;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  line-height: 1.4285em;
  color: #000;
  font-weight: 300;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

body,
html {
  height: 100%;

  margin: 0 !important;
  padding: 0 !important;
  background-color: #fff;
  color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
}

.ui.menu {
  margin: 0;
  font-size: 16px;
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
}

.ui.menu .item.home img {
  margin-right: 20px;
}

/* .article {
  background-color: white;
  padding: 50px;
} */

.article p {
  color: black;
}
/* .ui.grid > .row > .column,
.ui.grid > .column:not(.row) {
  padding-left: 0 !important;
} */

.ui.menu .right.item img {
  width: 100px;
}

@media (max-width: 767px) {
  .ui.section {
    margin: 0;
  }
}

p {
  word-wrap: break-word;
}

.ui.section {
  padding: 10px 30px;
}

@media (max-width: 767px) {
  .ui.section {
    padding: 0px 5px;
  }
}

.ui.grid > .column:not(.row) {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.ui.grid.smallgrid {
  padding-bottom: 0;
}

.smallcard-img {
  min-height: 100px;
  min-width: 100px;
  background-position: 0 0;
  background-size: cover;
  margin: 5px;
  cursor: pointer;
}

.ui.grid.cardsgrid {
  margin-top: 40px !important;
  padding: 0px;
}

.ui.grid.cardsgrid .column {
  padding: 0px 0px !important;
}

/* .ui.grid.smallgrid .column {
  padding: 5px 15px;
} */

.ui.grid.smallgrid .column > div {
  padding: 5px 5px !important;
}

.heading {
  margin-top: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 0.5em;
  line-height: 1.2em;
  position: absolute;
  width: 100%;
  color: #ffcc00;
}

#ball {
  margin-top: 0px;
}

.tv-static {
  background: repeating-radial-gradient(#000 0 0.0001%, #fff 0 0.0002%) 50% 0/2500px
      2500px,
    repeating-conic-gradient(#000 0 0.0001%, #fff 0 0.0002%) 60% 60%/2500px
      2500px;
  background-blend-mode: difference;
  animation: b 0.2s infinite alternate;
}
@keyframes b {
  100% {
    background-position: 50% 0, 60% 50%;
  }
}

h1 {
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  font-size: 3em;
  margin-top: 1em;
  color: #000;
  padding-bottom: 5px;
  line-height: 1;
}

/* .ui.grid > * {
  padding-left: 2.5em !important;
  padding-top: 1em !important;
} */

p {
  color: #000;
  font-family: "Work Sans", sans-serif;
  font-size: 1.5em;
  margin-top: 1em;
}

.portrait-card p,
.square-card p {
  color: #f2f2f2;
}

.intro-header {
  padding-top: 20px !important;
}

.intro-header p {
  padding: 0 5px;
}

.intro-header .arabic {
  text-align: right;
}

/* .intro-header p {
  font-size: 1.35rem;
  margin-bottom: 2rem;
} */

.fbxlink {
  text-decoration: none;
  cursor: pointer;
  font-size: 1.25rem;
  color: black;
  margin-top: 2rem;
}

strong {
  font-weight: 500;
}

.fbxlink:hover {
  color: black;
}

.image-credit {
  text-align: right;
  width: 100%;
}

.three-model {
  height: 500px;
  padding-bottom: 0px;
  cursor: all-scroll;
}

.language-wrapper {
  position: absolute;
  top: 0px;
  right: 120px;
  z-index: 100;
}

.article-header {
  text-transform: capitalize;
  padding-top: 10px;
}

.author {
  font-size: 1.5rem;
  padding: 0 0 10px 0;
  line-height: 1;
}

.artifact-credits {
  font-size: 1em;
}

.article-header.arabic {
  padding: 20px;
  text-align: right;
}

.article-header h1 {
  font-size: 3rem;
}

.article {
  padding-bottom: 5rem;
}

.article > div {
  margin-bottom: 30px;
}

.article p:first-of-type {
  initial-letter: 3 2;
}

.article-image {
  width: 100%;
  text-align: center;
  position: relative;
  /* background-color: rgba(0, 0, 0, 0.05); */
}

.article-image img {
  width: 100%;
  cursor: pointer;
  margin: 0 0 40px 0;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.38);
  -webkit-box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.38);
}

@media (max-width: 767px) {
  .ui.menu .item {
    padding: 5px;
  }
  .article-image img {
    max-height: auto;
    max-width: 300px;
    cursor: pointer;
    margin: 0 0 20px 0;
  }
}

div.author {
  font-weight: 300;
  clear: both;
}

/* .story-landing-page-article:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */

.content.landing-page {
  padding: 0;
}

.content.landing-page p {
  padding: 1rem;
}

.artifact-caption {
  padding: 0px 0px 40px 0;
  font-size: 0.75rem;
}

.artifact-title {
  padding-bottom: 20px;
  font-size: 22px;
  font-weight: 500;
}

.article-section-image {
  height: 400px;
  background-size: cover;
  background-position: center center;
}

.article h1 {
  color: #000;
}

.article-section {
  padding-top: 100px;
}

.fullscreen {
  height: 100%;
  width: 100%;
  position: absolute;
  display: none;
  /* z-index: 100; */
  margin: 0;
}

.full-width {
  width: 100%;
  min-width: 100%;
}

.ui.card,
.ui.card image,
.ui.card > :first-child {
  border-radius: 50% !important;
}
.ui.card .image {
  background-position: center center;
  background-size: cover;
  height: 400px;
}

.sideBySide {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

.cards-stories {
  min-width: 100%;
}

.square-card {
  min-width: 100%;
  min-height: 200px;
  border: 1px solid #f1f1f1;
}

.square-card > p {
  visibility: hidden;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 2rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 2vw;
  text-align: left;
  line-height: 2.5vw;
  font-weight: 500;
}

.square-card:hover > p {
  visibility: visible;
}

@media (max-width: 767px) {
  .square-card > p {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.ui.grid > .row {
  padding: 0;
}

.ui.grid {
  padding-bottom: 1.5rem;
  margin: 0 !important;
}

.top {
  background-color: white;
  color: black;
  font-family: "Work Sans", sans-serif;
  font-size: 1.5em;
}

.top.ui.borderless.menu.navbar.full-width {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
  background-color: #fff !important;
  z-index: 99;
}

.top .right {
  position: absolute;
  top: 10px;
  right: 10px;
}

.menu {
  border: none;
  box-shadow: none !important;
  padding: 0 0 0 0;
}

.mobile.only .top .right i {
  position: absolute;
  top: 2px;
  right: 0px;
  font-size: 1.5rem;
}

/* @media (min-width: 767px) {
  .mobile.only {
    display: block;
  }
  .computer.only {
    display: none;
  }
}

@media (min-width: 768px) {
  .mobile.only {
    display: none;
  }
  .computer.only {
    display: block;
  }
} */

/* .ui.grid {
  padding: 0 1rem;
} */

.enter-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Work Sans", sans-serif;
  font-size: 3em;
  color: #ffcc00;
  cursor: pointer;
}

.stage-two {
  display: none;
}

.augmented-reality {
  font-family: "Work Sans", sans-serif;
  color: #000;
  float: right;
  line-height: 5;
}

.circular-image-wrap {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.circular-image {
  position: relative;
  border-radius: 50%;
  width: 500px;
  height: 500px;
  background-size: cover;
  background-position: center center;
  border: 3px solid white;
}

.circular-card {
  position: relative;
  border-radius: 50%;
  width: 100%;
  aspect-ratio: 1 / 1;
  background-size: cover;
  background-position: center center;
  border: 3px solid white;
}

.portrait-card {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 2;
  background-size: cover;
  background-position: center center;
  border: 3px solid white;
}
.portrait-card .content {
  background-color: #000;
  position: absolute;
  bottom: 0;
  padding: 10px;
  width: 100%;
}

.square-card {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  background-size: cover;
  background-position: center center;
}
.square-card .content {
  display: none;
  background-color: #000;
  padding: 10px;
  width: 100%;
}
.square-card .content > p:first-child {
  padding-top: 10px;
}
.square-card:hover .content {
  display: block;
}

.content .meta,
.content .header {
  color: #999;
  font-family: "Work Sans", sans-serif;
  font-size: 1.3em;
}

.content .meta .image img {
  width: 40px;
  height: auto;
  float: right;
}

.circular-card .content {
  display: none;
}

.circular-image.adnano {
  background-image: url("../public/artists/1_Adnano-Samman_Syria/1.jpg");
}

.ui.container {
  padding: 10px 30px;
}

@media (max-width: 767px) {
  .ui.container {
    padding: 0px 5px;
  }
}

.threejs-bg {
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
}

.stage-one {
  opacity: 100;
  z-index: 100;
}

.scanlines {
  cursor: pointer;
}

.languages {
  display: flex !important;
}

.lang {
  color: #000;
  cursor: pointer;
  margin-right: 10px;
  padding: 10px 10px;
}

@media (max-width: 767px) {
  .lang {
    font-size: 0.8em;
    margin-right: 5px;
  }
}

.lang:hover,
.lang-selected {
  background: #fff;
  color: #5508d3;
}

.solferino-logo {
  position: absolute;
  top: 5px;
  right: 5px;
}

.item.home > strong {
  font-size: 2em;
  padding-top: 2em;
  padding-left: 0.85em;
}
